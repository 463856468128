/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Link } from 'gatsby';
import { useState } from 'react';
import { ReactComponent as Logo } from '../assets/icons/logo.svg';
import { ReactComponent as ArrowDownIcon } from '../assets/icons/arrow-down.svg';
import { ReactComponent as ActivityIcon } from '../assets/icons/activity.svg';
import { ReactComponent as SearchIcon } from '../assets/icons/search.svg';
// import { ReactComponent as HomeIcon } from '../assets/icons/home.svg';
import { ReactComponent as ShoppingCartIcon } from '../assets/icons/shopping-cart.svg';
import { ReactComponent as MessageIcon } from '../assets/icons/message.svg';
import { Squash as Hamburger } from 'hamburger-react';
import useScrollBlock from '../utils/use-scroll-block';

// const DROPDOWN_ITEMS = [
//   {
//     heading: 'Stocks and Data',
//     text: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Autem hic ipsum fugiat. Maxime illo laudantium consequuntur totam distinctio perferendis omnis? ',
//     icon: <ActivityIcon />,
//   },
//   {
//     heading: 'Search Engine Results',
//     text: 'Get page listings for each search engine. Messages from customers with previous orders that request returns or exhanges.',
//     icon: <SearchIcon />,
//   },
//   {
//     heading: 'Social Content',
//     text: 'Gather social profiles, photos, videos, stories, and reels — from Instagram, TikTok, LinkedIn and other sites.',
//     icon: <MessageIcon />,
//   },
//   {
//     heading: 'Product Pages',
//     text: 'Get products, prices, and descriptions from product listing pages on e-commerce platforms like Amazon, eBay, and others.',
//     icon: <ShoppingCartIcon />,
//   },
// ];

const Header = () => {
  const [blockScroll, allowScroll] = useScrollBlock();
  const [open, setOpen] = useState(false);

  const handleMenuClick = () => {
    setOpen(!open);
    if (!open) {
      blockScroll();
    } else {
      allowScroll();
    }
  };

  return (
    <header sx={styles.header}>
      <nav sx={styles.nav}>
        <Link to="/" sx={styles.logo}>
          <Logo />
        </Link>

        <div sx={styles.navListContainer} className={open ? 'open' : null}>
          <ul sx={styles.navList}>
            {/* <li sx={{ ...styles.navItem, ...styles.navItemDropdown }}>
              <Link sx={styles.navLink} to="/">
                <span>Products</span>
                <span sx={{ ml: '8px', display: ['none', 'inline-block'] }}>
                  <ArrowDownIcon />
                </span>
              </Link>
              <div sx={styles.dropdownContent} className="dropdown__content">
                <ul>
                  {DROPDOWN_ITEMS.map((dropdownItem) => {
                    return (
                      <li sx={styles.dropdownItem}>
                        <span sx={styles.dropdownIconWrapper}>
                          {dropdownItem.icon}
                        </span>
                        <div>
                          <h3>{dropdownItem.heading}</h3>
                          <p>{dropdownItem.text}</p>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </li> */}
            <li sx={styles.navItem}>
              <Link sx={styles.navLink} to="/pricing">
                Pricing
              </Link>
            </li>
            <li sx={styles.navItem}>
              <Link sx={styles.navLink} to="/docs">
                Docs
              </Link>
            </li>
            <li sx={styles.navItem}>
              <Link sx={styles.navLink} to="/blog">
                Blog
              </Link>
            </li>
          </ul>

          <ul sx={styles.ctaList}>
            <li sx={styles.navItem}>
              <a sx={styles.navLink} href="https://app.scrapeowl.com/login">
                Log in
              </a>
            </li>
            <li sx={styles.navItem}>
              <a sx={styles.ctaLink} href="https://app.scrapeowl.com/register">
                Sign up
              </a>
            </li>
          </ul>
        </div>

        <button sx={styles.menuButton} onClick={handleMenuClick}>
          <Hamburger />
        </button>
      </nav>
    </header>
  );
};

export default Header;

const styles = {
  header: {
    paddingTop: 4,
    variant: 'layout.container',
  },
  nav: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    textAlign: ['center', 'left'],
  },
  navList: {
    display: 'flex',
    flexDirection: ['column', 'row'],
    width: ['100%', 'auto'],
  },
  ctaList: {
    marginLeft: [0, 'auto'],
    width: ['100%', 'auto'],
    display: 'flex',
    alignItems: 'center',
    flexDirection: ['column', 'row'],

    li: {
      width: ['70%', 'fit-content'],
    },
  },
  navListContainer: {
    display: 'flex',
    flexDirection: ['column', 'row'],
    justifyContent: ['center', 'space-between'],
    bg: ['purplegray', 'transparent'],
    alignItems: 'center',
    zIndex: 1,
    m: 0,
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    position: ['fixed', 'static'],
    width: '100%',
    height: ['100vh', 'initial'],
    clipPath: ['circle(10px at 90% -10%)', 'initial'],
    transition: 'all .5s ease-out',

    '&.open': {
      clipPath: 'circle(1000px at 90% -10%)',
    },
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: ['absolute', 'static'],
    zIndex: 2,
    top: 0,
    left: 0,
  },
  menuButton: {
    color: 'lighterpurplegray',
    bg: 'transparent',
    border: 'none',
    display: ['flex', 'none'],
    position: ['absolute'],
    zIndex: 2,
    top: -3,
    right: 0,
    p: 0,
    m: 0,
  },
  navItem: {
    marginLeft: [0, 4],
    mb: [3, 'unset'],
  },
  navLink: {
    fontWeight: 600,
    color: '#767683',
    padding: 2,
    transition: 'color 0.25s ease-out',
    display: 'inline-block',
    width: '100%',

    '&:hover': {
      color: '#ff8b48',
    },
  },
  ctaLink: {
    minWidth: '105px',
    width: ['100%', 'auto'],
    display: 'inline-block',
    whiteSpace: 'nowrap',
    py: '13px',
    px: '25px',
    color: 'white',
    bg: 'orange',
    borderRadius: '8px',
    fontWeight: 600,
    transition: 'background 0.25s ease-out',

    '&:hover': {
      bg: '#e67d42',
    },
  },

  navItemDropdown: {
    position: 'relative',

    '&:hover': {
      '.dropdown__content': {
        display: ['none', 'block'],
        opacity: 1,
      },
    },
  },
  dropdownContent: {
    position: 'absolute',
    top: '100%',
    borderRadius: '8px',
    minWidth: '30rem',
    left: '-25%',
    mt: '.5rem',
    p: 3,
    transition: 'opacity 0.5s ease-out',
    zIndex: 3,
    bg: 'white',
    opacity: 0.4,
    border: '1px solid hsla(0, 0%, 0%, 0.1)',
    width: 'auto',
    display: 'none',

    '&:before': {
      content: '""',
      position: 'absolute',
      top: -3,
      bottom: -3,
      left: -3,
      right: -3,
      zIndex: -3,
    },

    ul: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },

  dropdownItem: {
    display: 'flex',
    // border: '1px solid red',
    pt: 2,

    '&:not(:first-child)': {
      mt: 4,
    },

    h3: {
      color: 'purplegray',
      fontSize: 2,
      fontWeight: 600,
    },

    p: {
      color: 'grey',
    },
  },

  dropdownIconWrapper: {
    mr: 3,
    color: 'purple',
  },
};
