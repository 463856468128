/** @jsx jsx */
import { jsx } from 'theme-ui';
import { ReactComponent as Logo } from '../assets/icons/logo.svg';
import { Link } from 'gatsby';

const Footer = () => {
  return (
    <footer sx={{ mt: 'auto' }}>
      <div sx={styles.footer}>
        <div sx={styles.firstRow}>
          <div sx={styles.iconWrapper}>
            <Logo />
            <span>ScrapeOwl</span>
          </div>

          <p>Web scraping API and proxy service</p>
        </div>
      </div>

      <div>
        <div sx={styles.secondRow}>
          <p>{`©`} ScrapeOwl {new Date().getFullYear()}</p>

          <ul sx={styles.navList}>
            <li>
              <a href="https://app.scrapeowl.com/login">Login</a>
            </li>
            {/* <li>
              <Link to="/">Affiliates</Link>
            </li> */}
            <li>
              <Link to="/terms">Terms</Link>
            </li>
            <li>
              <Link to="/privacy">Privacy</Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

const styles = {
  footer: {
    variant: 'layout.container',
    flexWrap: 'wrap',
  },
  firstRow: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: ['center', 'space-between'],
    pb: '23px',
    color: 'hsl(240, 7%, 57%, 0.5)',
    fontWeight: 600,
    fontSize: '14px',

    '& > *:not(:first-child)': {
      m: [3, 'unset'],
    },
  },

  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',

    '& > *:not(:first-child)': {
      ml: '1rem',
    },
  },

  secondRow: {
    variant: 'layout.container',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: ['center', 'space-between'],
    borderTop: '1px dashed hsla(0, 0%, 0%, 0.1)',
    color: 'hsla(0, 0%, 0%, 0.3)',
    fontWeight: 600,
    pt: '28px',
    pb: '23px',
    fontSize: '14px',
  },

  navList: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',

    '& > *': {
      m: [3, 'unset'],
    },

    '& > *:not(:first-child)': {
      ml: ['unset', '56px'],
    },

    a: {
      color: 'hsla(0, 0%, 0%, 0.3)',
      fontSize: '14px',
      transition: 'color 0.25s ease-out',

      '&:hover': {
        color: '#767683',
      },
    },
  },
};
