/** @jsx jsx */
import Cookie from "js-cookie";
import { jsx } from "theme-ui";
import { Fragment, useEffect } from "react";
import Seo from "./seo";
import Footer from "./footer";
import Header from "./header";

const Layout = ({ children, title, description }) => {
  useEffect(() => {
    const url = new URL(window.location);
    const urlParams = url.searchParams;
    const ref = urlParams.get("ref");

    if (ref && ref == "zimmwriter") {
      Cookie.set("zimmwriter", "zimmwriter", {
        path: "/",
        domain: ".scrapeowl.com",
        expires: 30,
      });
    }

    if (ref && ref == "aimasher") {
      Cookie.set("aimasher", "aimasher", {
        path: "/",
        domain: ".scrapeowl.com",
        expires: 30,
      });
    }

    if (ref && ref == "seomastersuite") {
      Cookie.set("seomastersuite", "seomastersuite", {
        path: "/",
        domain: ".scrapeowl.com",
        expires: 30,
      });
    }
  }, []);
  return (
    <Fragment>
      <Seo title={title} description={description} />
      <div
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",

          main: {
            flex: "666 1 auto",
          },
        }}
      >
        <Header />
        <main sx={styles.main}>{children}</main>
        <Footer />
      </div>
    </Fragment>
  );
};

export default Layout;

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100%",
  },

  main: {
    flex: "1 1 auto",
  },
};
